import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-primary.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "the-pyp-within-the-ibo-continuum",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-pyp-within-the-ibo-continuum",
        "aria-label": "the pyp within the ibo continuum permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The PYP within the IBO continuum`}</h2>
    <p>{`The three IB programmes share many educational principles. The IB aims to promote:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`International understanding`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Responsible citizenship`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The importance of learning how to learn, and of student-centred inquiry`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Communication`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Student Voice`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ownership of Choice`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Student Agency`}</p>
      </li>
    </ul>
    <p>{`The PYP builds on a combination of wide-ranging research and experience and draws on best practice from national systems around the world. The PYP endorses a belief that students learn best when the learning is authentic and transdisciplinary - relevant to the real world.`}</p>
    <p>{`The PYP acknowledges the importance of educating children in separate subject areas, and recognises that ‘student learning is best done when learning is not confined within the boundaries of traditional subject areas but is supported and enriched by them.’  (`}<em parentName="p">{`Making the PYP Happen: A Curriculum Framework for International Primary Education,`}</em>{` 2009).  For this reason the greater part of the curriculum is delivered through six transdisciplinary units of inquiry (four for the Early Childhood programme), one from each knowledge area.  Through these units of inquiry, students explore globally significant themes, each of which is built around a central idea.`}</p>
    <h2 {...{
      "id": "pyp-transdisciplinary-themes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#pyp-transdisciplinary-themes",
        "aria-label": "pyp transdisciplinary themes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PYP Transdisciplinary Themes`}</h2>
    <h4 {...{
      "id": "who-we-are",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#who-we-are",
        "aria-label": "who we are permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Who we are`}</h4>
    <p>{`An Inquiry into the nature of the self; beliefs and values; personal, physical, mental, social and spiritual health; human relationships including families, friends, communities and cultures; rights and responsibilities; what it means to be human.`}</p>
    <h4 {...{
      "id": "where-we-are-in-place-and-time",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#where-we-are-in-place-and-time",
        "aria-label": "where we are in place and time permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Where we are in place and time`}</h4>
    <p>{`An inquiry into orientation in place and time; personal histories; home and journeys; the discoveries, explorations and migrations of humankind; the relationships between and the interconnectedness of individuals and civilizations, from local and global perspectives.`}</p>
    <h4 {...{
      "id": "how-we-express-ourselves",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#how-we-express-ourselves",
        "aria-label": "how we express ourselves permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How we express ourselves`}</h4>
    <p>{`An inquiry into the ways in which we discover and explore ideas, feelings, nature, culture, beliefs and values; the ways in which we reflect on, extend and enjoy our creativity; our appreciation of the aesthetic.`}</p>
    <h4 {...{
      "id": "how-the-world-works",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#how-the-world-works",
        "aria-label": "how the world works permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How the world works`}</h4>
    <p>{`An inquiry into the natural world and its laws; the interaction between the natural world (physical and biological) and human societies; how humans use their understanding of scientific principles; the impact of scientific and technological advances on society and on the environment.`}</p>
    <h4 {...{
      "id": "how-we-organize-ourselves",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#how-we-organize-ourselves",
        "aria-label": "how we organize ourselves permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How we organize ourselves`}</h4>
    <p>{`An inquiry into the interconnectedness of human-made systems and communities; the structure and function of organisations; societal decision making; economic activities and their impact on humankind and the environment.`}</p>
    <h4 {...{
      "id": "sharing-the-planet",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#sharing-the-planet",
        "aria-label": "sharing the planet permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sharing the planet`}</h4>
    <p>{`An inquiry into the rights and responsibilities in the struggle to share finite resources with other people and with other living things; communities and the relationship within and between them; access to equal opportunities; peace and conflict resolution.`}</p>
    <h2 {...{
      "id": "the-pyp-programme-of-inquiry",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-pyp-programme-of-inquiry",
        "aria-label": "the pyp programme of inquiry permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The PYP programme of inquiry`}</h2>
    <p>{`Grade One through Grade Five, students engage with up to six units of inquiry, one for each transdisciplinary theme. Our Early Childhood Programme (3 and 4 year olds) students are required to complete two mandatory units of inquiry: `}<em parentName="p">{`Who we are`}</em>{`, and `}<em parentName="p">{`How we express ourselves,`}</em>{` plus two more units selected by the school. Kindergarten students complete `}<em parentName="p">{`Who we are`}</em>{`, and `}<em parentName="p">{`How we express ourselves`}</em>{` and two additional units of inquiry. The programme of inquiry includes learning in all subject areas: mathematics, science, language, the arts, physical education and social studies.`}</p>
    <h2 {...{
      "id": "units-of-inquiry",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#units-of-inquiry",
        "aria-label": "units of inquiry permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Units of Inquiry`}</h2>
    <p>{`Units begin by either making a connection between a topic (or concept) and something students are familiar with or with a thought provoking activity to introduce the inquiry.Teachers organise activities to explore (pre-assess) students' knowledge of the topic. Videos, books, field trips, guest speakers and discussions are examples of ways a teacher might begin this process. Often referred to as ‘wandering and wondering’, this is when students begin to generate questions that are usually recorded and displayed in the classroom. sThese questions, along with teacher-generated questions, are the starting point of individual and class inquiry that takes place as each unit progresses.`}</p>
    <p>{`The Central Idea of each unit is a powerful statement or generalisation that anchors the unit. It helps to keep the unit focused and guarantees that inquiries go beyond collecting information and lead to deeper understanding of concepts and the relationship between concepts.`}</p>
    <p>{`Throughout the learning and teaching programme, there is a focus on assessment which allows students to demonstrate their understanding of the central idea and give feedback to further guide the design of learning engagements. Assessments can be both formative or summative and are usually an authentic task in which the students must apply and synthesise their understanding of the concepts studied and demonstrate their understanding through a variety of activities and presentation techniques. Often, but not always, parents are invited to attend when their children are presenting their summative assessment work at an assembly or within the classroom.`}</p>
    <h2 {...{
      "id": "the-pyp-classroom",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-pyp-classroom",
        "aria-label": "the pyp classroom permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The PYP Classroom`}</h2>
    <p>{`Inquiry based learning is evident in the classroom arrangement and activities. Students work in a variety of grouping situations to facilitate group work and discussion. There is a place for the class to sit together for discussions and places where small groups can go to work away from the rest of the class. Student learning is displayed on the walls. Ample and varied resources are available to students. The Learner Profile and Student Attitudes as well as the central idea and unit focus are displayed and referred to often during the learning day.`}</p>
    <p>{`Significant time is allowed for student discussion and exchange of ideas. Often during whole class discussions, the teacher will record and display the content of discussions so that they can be referenced during the learning journey. During small group discussions, students will record their information to then share with the class or to refer back to during their research or later work.`}</p>
    <p>{`Students are provided with the opportunity to work individually, in pairs, in small groups and in larger groups. There is time for whole class instruction, small group instruction and individual support.`}</p>
    <p>{`Classrooms are busy with student-led initiatives as well as teacher-directed assignments.Students are helped to take responsibility for their own learning, to assist each other, locate their own resources, decide on activities to deepen their understanding and to demonstrate what they know. Students are given time to reflect on their own learning, encouraged to make connections to previous learning, identify new interests and questions sparked by the current unit, and to act on new information and understanding.`}</p>
    <h2 {...{
      "id": "scope-and-sequence-documents",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#scope-and-sequence-documents",
        "aria-label": "scope and sequence documents permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Scope and Sequence Documents`}</h2>
    <p>{`Scope and sequence documents delineate the development in each subject area that is taught at each grade level. The format is suggested by the IBPYP and is common to all IB PYP schools.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      